import React, { useState, useMemo } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import CommonHollowModal from '../../common/CommonHollowModal'
import Tab from '../../common/Tab'
import BalanceInput from '../../common/Input/BalanceInput'
import { useStake, useUnstake } from '../../../hooks/useGauge'
import { isInvalidAmount, ZERO_ADDRESS } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'
import { TransactButton } from '../../common/Buttons/transactButton'

const DepositModal = ({ isOpen, setIsOpen, pair, setStaking, stake = true }) => {
  const [isStake, setIsStake] = useState(stake)
  const [stakeAmount, setStakeAmount] = useState('')
  const { onStake, pending: stakePending } = useStake()
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const { onUnstake, pending: unstakePending } = useUnstake()

  const stakeBtnMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(stakeAmount)) {
        return {
          isError: true,
          label: 'INVALID AMOUNT',
        }
      }

      if (pair.account.lpBalance.lt(stakeAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT LP BALANCE',
        }
      }
      if (pair.gauge.address === ZERO_ADDRESS) {
        return {
          isError: true,
          label: 'INVALID GAUGE',
        }
      }
    }
    return {
      isError: false,
      label: 'STAKE LP',
    }
  }, [pair, stakeAmount])

  const unstakeErrorMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return 'Invalid Amount'
      }
      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return 'Insufficient ' + pair.symbol + ' Balance'
      }
    }
    return null
  }, [withdrawAmount, pair])

  const unstakeBtnMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return {
          isError: true,
          label: 'INVALID AMOUNT',
        }
      }

      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT LP BALANCE',
        }
      }
    }
    return {
      isError: false,
      label: 'UNSTAKE LP',
    }
  }, [pair, withdrawAmount])

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage liquidity`}>
      <div className='w-full mt-[29px] flex items-center justify-center flex-col'>
        <Tab leftTitle={'STAKE'} rightTitle={'UNSTAKE'} isLeft={isStake} setIsLeft={setIsStake} />
        {isStake ? (
          <div className='w-full flex items-center justify-center flex-col mt-5'>
            <BalanceInput
              title=''
              inputAmount={stakeAmount}
              setInputAmount={setStakeAmount}
              symbol={pair.symbol}
              balance={pair.account.lpBalance}
              logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
            />
            <div className='flex items-center space-x-2 my-4 group cursor-pointer' href='#' onClick={() => setStaking(false)}>
              <p className='text-sm text-white font-medium'>Get {pair.symbol} LP</p>
              <img className='group-hover:w-[32px] w-[26px] duration-300 ease-in-out stroke-white' alt='' src='/images/common/spear.svg' />
            </div>
            <div className='flex items-center w-full space-x-3'>
              <TransactButton
                content={stakePending ? 'PENDING...' : stakeBtnMsg.label}
                disabled={stakePending || stakeBtnMsg.isError}
                onClickHandler={() => {
                  if (stakePending) {
                    return
                  }
                  onStake(pair, stakeAmount)
                  setIsOpen(false)
                }}
                className='w-1/2 py-3 text-xs md:text-sm'
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'CANCEL'}
                className='flex items-center justify-center py-[11px] text-white text-xs md:text-sm !font-light w-1/2 tracking-wider hover:bg-themeOrangeLight/30'
              />
            </div>
          </div>
        ) : (
          <div className='w-full flex items-center justify-center flex-col mt-5'>
            <BalanceInput
              title=''
              inputAmount={withdrawAmount}
              setInputAmount={setWithdrawAmount}
              symbol={pair.symbol}
              balance={pair.account.withdrawBalance}
              logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
            />
            <div className='flex items-center space-x-2 my-4 group cursor-pointer' href='#' onClick={() => setStaking(false)}>
              <p className='text-sm text-white font-medium'>Get {pair.symbol} LP</p>
              <img className='group-hover:w-[32px] w-[26px] duration-300 ease-in-out stroke-white' alt='' src='/images/common/spear.svg' />
            </div>
            <div className='flex items-center w-full space-x-3'>
              <TransactButton
                content={unstakePending ? 'PENDING...' : unstakeBtnMsg.label}
                disabled={unstakePending || unstakeBtnMsg.isError}
                onClickHandler={() => {
                  if (unstakeErrorMsg) {
                    customNotify(unstakeErrorMsg, 'warn')
                    return
                  }
                  onUnstake(pair, withdrawAmount)
                  setIsOpen(false)
                }}
                className='w-1/2 py-3 text-xs md:text-sm'
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'CANCEL'}
                className='flex items-center justify-center py-[11px] text-white text-xs md:text-sm !font-light w-1/2 tracking-wider hover:bg-themeOrangeLight/30'
              />
            </div>
          </div>
        )}
      </div>
    </CommonHollowModal>
  )
}

export default DepositModal
