/* global gtag */

export const trackSwap = ({ eventName, walletAddress, fromAmount, fromCurrency, toAmount, toCurrency, contractAddress, amountUSD }) => {
  const eventType = 'swap'
  const parameters = {
    walletAddress: walletAddress.substring(2),
    fromAmount,
    fromCurrency,
    contractAddress: contractAddress.substring(2),
    toAmount,
    toCurrency,
    amountUSD,
  }
  trackEvent(eventType, eventName, parameters)
}

export const trackRewards = ({ walletAddress, amountUSD, rebase = false, harvest = false }) => {
  const eventType = 'claim'
  const eventName = harvest ? 'harvest-rewards' : rebase ? 'claim-rebase' : 'claim-all-rewards'
  const parameters = { walletAddress: walletAddress.substring(2), amountUSD }
  trackEvent(eventType, eventName, parameters)
}

export const trackLiquidity = ({
  walletAddress,
  amount,
  amount0,
  amount1,
  currency,
  contractAddress,
  amountUSD,
  alm,
  add = false,
  remove = false,
  unstake = false,
}) => {
  const eventType = 'deposit'
  const eventName = remove ? 'remove-liquidity' : unstake ? 'unstake-liquidity' : add ? 'add-liquidity' : 'stake-liquidity'
  let parameters
  if (add) {
    parameters = {
      walletAddress: walletAddress.substring(2),
      amount,
      amount0,
      amount1,
      currency,
      contractAddress: contractAddress.substring(2),
      amountUSD,
      alm,
    }
  } else {
    parameters = {
      walletAddress: walletAddress.substring(2),
      amount,
      currency,
      contractAddress: contractAddress.substring(2),
      amountUSD,
      alm,
    }
  }
  trackEvent(eventType, eventName, parameters)
}

export const trackLocks = ({ walletAddress, amount, veLynxId, delegatee, unlockDate, maxLocked, delegate = false }) => {
  if (delegate) {
    const eventType = 'delegate'
    const eventName = 'delegate-velynx'
    const parameters = {
      walletAddress: walletAddress.substring(2),
      veLynxId,
      delegatee: delegatee.substring(2),
    }
    trackEvent(eventType, eventName, parameters)
  } else {
    const eventType = 'lock'
    const eventName = 'lock-lynx'
    const parameters = {
      walletAddress: walletAddress.substring(2),
      amount,
      unlockDate,
      maxLocked,
    }
    trackEvent(eventType, eventName, parameters)
  }
}

export const trackOptions = ({ walletAddress, fromAmount, toAmount, amountUSD, olynxToLynx = false, olynxToVeLynx = false }) => {
  const eventType = 'swap'
  const eventName = olynxToLynx ? 'olynx-to-lynx' : olynxToVeLynx ? 'olynx-to-velynx' : 'bvelynx-to-velynx'
  const parameters = {
    walletAddress: walletAddress.substring(2),
    fromAmount,
    fromCurrency: olynxToLynx || olynxToVeLynx ? 'olynx' : 'bvelynx',
    contractAddress: '',
    toAmount: olynxToLynx ? toAmount : fromAmount,
    toCurrency: olynxToLynx ? 'lynx' : 'velynx',
    amountUSD,
  }
  trackEvent(eventType, eventName, parameters)
}

const trackEvent = (eventType, eventName, params) => {
  // window.safary.track({
  //   eventType,
  //   eventName,
  //   parameters: params,
  // })
  trackGoogle(eventType, eventName, params)
}

function trackGoogle(eventType, eventName, params) {
  const mappedParams = {}
  Object.keys(params).forEach((key) => {
    const mappedKey = analyticsMapper[key] || key
    if (params[key]) mappedParams[mappedKey] = params[key]
  })
  const data = {
    eventType: eventType,
    ...params,
    ...mappedParams,
  }
  console.log({ eventName, data })
  gtag('event', eventName, data)
}

// NOTE: Using pre-existing analytics metrics and dimensions to map into our data
const analyticsMapper = {
  amountUSD: 'value',
  fromCurrency: 'currency',
  fromAmount: 'price',
  toAmount: 'quantity',
  toCurrency: 'item_id',
  contractAddress: 'item_list_name',
  walletAddress: 'userAppWalletId',
  delegatee: 'item_list_id',
  veLynxId: 'level',
}
