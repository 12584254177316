import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNetwork } from 'wagmi'
import useWeb3, { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { useCurrency } from '../../../hooks/v3/Tokens'
import { isZero, tryParseAmount } from '../../../v3lib/utils/utils'
import BN from 'bignumber.js'
import Modal from '../../common/Modal'
import StyledButton from '../../common/Buttons/styledButton'
import styled from 'styled-components'
import Toggle from '../../common/Toggle'
import { formatAmount, fromWei, MAX_UINT256 } from '../../../utils/formatNumber'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { useCallback } from 'react'
import { constructSDK, permit2Address, zeroAddress } from '@orbs-network/liquidity-hub-sdk'
import { SwapFlow, PoweredByOrbs, WEBSITE_URL, SwapStatus } from '@orbs-network/swap-ui'
import { getAllowance } from '../../../utils/api'
import { getERC20Contract, getWBNBContract } from '../../../utils/contractHelpers'
import { MdOutlineSwapVert, MdCheckCircle, MdOutlineError } from 'react-icons/md'
import { _TypedDataEncoder } from 'ethers/lib/utils'
import { Connectors, EnabledDexIds, KYBER_API, ODOS_ASSEMBLE } from '../../../hooks/useSwap'
import Web3 from 'web3'
import { useGasPrice } from '../../../hooks/useGeneral'
import { formatGwei } from 'viem'
import { Web3Context } from '../../../context/Web3Conetext'
import { useSwapCallArguments } from '../../../hooks/v3/useSwapCallback'
import { useBestV3TradeExactIn } from '../../../hooks/v3/useBestV3Trade'
import { Percent } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { getFusionRouterAddress, getOpenOceanRouterAddress } from '../../../utils/addressHelpers'
import { useDispatch } from 'react-redux'
import { closeTransaction, openTransaction } from '../../../state/transactions/actions'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../../config/constants'

const Steps = {
  WRAP: 0,
  APPROVE: 1,
  SWAP: 2,
}

const useLiquidityHubSDK = () => {
  const { chain } = useNetwork()

  return useMemo(() => constructSDK({ chainId: chain?.id, partner: 'lynex' }), [chain?.id])
}

export const useLiquidityHubQuoteCallback = (fromAsset, toAsset, fromAmount, dexAmountOut, slippage) => {
  const inCurrency = useCurrency(fromAsset?.address)
  const outCurrency = useCurrency(toAsset?.address)
  const wrappedToken = useWrappedToken()

  const inTokenAddress = inCurrency?.isNative ? wrappedToken.address : inCurrency?.address
  const outTokenAddress = outCurrency?.isNative ? zeroAddress : toAsset?.address

  const { account } = useWeb3Wagmi()

  const sdk = useLiquidityHubSDK()

  return useCallback(async () => {
    return promiseWithRetry(async () => {
      if (!fromAsset || !toAsset || !fromAmount || !inTokenAddress || !outTokenAddress) return
      return await sdk.getQuote({
        inAmount: tryParseAmount(fromAmount, inCurrency)?.quotient.toString(),
        dexMinAmountOut: getDexMinAmountOut(dexAmountOut, slippage),
        slippage,
        fromToken: inTokenAddress,
        toToken: outTokenAddress,
        account,
      })
    })
  }, [fromAmount, inCurrency, sdk, fromAsset, toAsset, account, slippage, dexAmountOut, inTokenAddress, outTokenAddress])
}

function promiseWithRetry(promiseFactory, retries = 2, delay = 1000) {
  return new Promise((resolve, reject) => {
    const attempt = (currentRetry) => {
      promiseFactory()
        .then(resolve) // If successful, resolve the promise
        .catch((error) => {
          if (currentRetry < retries) {
            console.warn(`Attempt ${currentRetry} failed. Retrying in ${delay}ms...`)
            setTimeout(() => attempt(currentRetry + 1), delay)
          } else {
            reject(`Failed after ${retries} attempts: ${error}`)
          }
        })
    }

    attempt(1) // Start with the first attempt
  })
}

const getBetterPrice = async (slippage, dexAmountOut, fetchLiquidityHubQuote) => {
  try {
    const dexMinAmountOut = getDexMinAmountOut(dexAmountOut, slippage) || 0

    const quote = await promiseWithTimeout(fetchLiquidityHubQuote(), 5_000)

    return BN(quote?.outAmount || 0).gt(dexMinAmountOut)
  } catch (error) {
    return false
  }
}

export const useSeekingBestPrice = (fetchLiquidityHubQuote) => {
  const [seekingBestPrice, setSeekingBestPrice] = useState(false)
  const dispatch = useDispatch()
  const getBetterPrice = useCallback(
    async (dexOutAmount, slippage, skip) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      if (skip) {
        return
      }
      try {
        setSeekingBestPrice(true)
        dispatch(
          openTransaction({
            key,
            title: `Swap`,
            transactions: {
              [approveuuid]: {
                desc: `Seeking better price`,
                status: TransactionType.PENDING,
                hash: null,
              },
            },
          }),
        )
        const dexMinAmountOut = deductSlippage(dexOutAmount, slippage)
        const quote = await promiseWithTimeout(fetchLiquidityHubQuote(), 8_000)
        return BN(quote?.userMinOutAmountWithGas || 0).gt(dexMinAmountOut) ? quote : undefined
      } catch (error) {
        return
      } finally {
        setSeekingBestPrice(false)
        dispatch(
          closeTransaction({
            key,
          }),
        )
      }
    },
    [fetchLiquidityHubQuote, setSeekingBestPrice, dispatch],
  )

  return {
    seekingBestPrice,
    getBetterPrice,
  }
}

export const deductSlippage = (amount, slippage) => {
  if (!amount || !slippage) return amount
  return BN(amount)
    .times(100 - slippage)
    .div(100)
    .decimalPlaces(0)
    .toFixed()
}

export async function promiseWithTimeout(promise, timeout) {
  let timer

  const timeoutPromise = new Promise((_, reject) => {
    timer = setTimeout(() => {
      reject(new Error('timeout'))
    }, timeout)
  })

  try {
    const result = await Promise.race([promise, timeoutPromise])
    clearTimeout(timer)
    return result
  } catch (error) {
    clearTimeout(timer)
    throw error
  }
}

const useApproveCallback = () => {
  const sdk = useLiquidityHubSDK()
  const web3 = useWeb3()
  const { fromAsset } = useConfirmationContext()
  const { account } = useWeb3Wagmi()
  const inCurreny = useCurrency(fromAsset?.address)
  const wrappedToken = useWrappedToken()
  const tokenAddress = inCurreny?.isNative ? wrappedToken.address : inCurreny?.address

  return useCallback(async () => {
    sdk.analytics.onApprovalRequest()
    try {
      const tokenContract = getERC20Contract(web3, tokenAddress)

      await tokenContract.methods.approve(permit2Address, MAX_UINT256).send({
        from: account,
        value: '',
        gasLimit: '',
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      sdk.analytics.onApprovalSuccess()
    } catch (error) {
      sdk.analytics.onApprovalFailed(error.message)
      throw error
    }
  }, [account, sdk, web3, tokenAddress])
}

const useSignEIP712Callback = () => {
  const sdk = useLiquidityHubSDK()
  const web3 = useWeb3()
  const { account } = useWeb3Wagmi()
  return useCallback(
    async (permitData) => {
      sdk.analytics.onSignatureRequest()
      const provider = web3.currentProvider.request ? web3.currentProvider : web3.givenProvider.request ? web3.givenProvider : web3._provider
      if (!provider || !account) {
        throw new Error('No library or account')
      }
      try {
        const signature = await signEIP712(account, provider, permitData)
        if (!signature) {
          throw new Error('No signature')
        }
        sdk.analytics.onSignatureSuccess(signature)
        return signature
      } catch (error) {
        sdk.analytics.onSignatureFailed(error.message)
        throw error
      }
    },
    [account, sdk, web3],
  )
}

export const isRejectedError = (error) => {
  const message = error.message?.toLowerCase()
  return message?.includes('rejected') || message?.includes('denied')
}

async function signEIP712(signer, provider, permitData) {
  const populated = await _TypedDataEncoder.resolveNames(permitData.domain, permitData.types, permitData.values, async (name) => name)

  const message = JSON.stringify(_TypedDataEncoder.getPayload(populated.domain, permitData.types, populated.value))

  try {
    return await signAsync(signer, provider, 'eth_signTypedData_v4', message)
  } catch (e) {
    if (isRejectedError(e)) {
      throw e
    }
    try {
      return await signAsync(signer, provider, 'eth_signTypedData', message)
    } catch (error) {
      if (
        typeof error.message === 'string' &&
        (error.message.match(/not (found|implemented)/i) ||
          error.message.match(/TrustWalletConnect.WCError error 1/) ||
          error.message.match(/Missing or invalid/))
      ) {
        throw new Error('Wallet does not support EIP-712')
      } else {
        throw error
      }
    }
  }
}

async function signAsync(signer, provider, method, message) {
  return await provider?.request({
    method,
    params: [signer, message],
  })
}

const useWrapCallback = () => {
  const { account } = useWeb3Wagmi()
  const web3 = useWeb3()
  const sdk = useLiquidityHubSDK()
  const { fromAmount } = useConfirmationContext()
  return useCallback(async () => {
    try {
      sdk.analytics.onWrapRequest()
      const wbnbContract = getWBNBContract(web3)
      await wbnbContract.methods.deposit().send({
        from: account,
        value: fromAmount,
        gasLimit: '',
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      sdk.analytics.onWrapSuccess()
    } catch (error) {
      sdk.analytics.onWrapFailure(error.message)
      throw error
    }
  }, [account, web3, sdk, fromAmount])
}

export const useOdosTxData = () => {
  const { bestTrade } = useConfirmationContext()
  const { account } = useWeb3Wagmi()
  return useCallback(async () => {
    const assembleRequestBody = {
      userAddr: Web3.utils.toChecksumAddress(account),
      pathId: bestTrade?.pathId,
      simulate: true,
    }

    const response = await fetch(ODOS_ASSEMBLE, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(assembleRequestBody),
    })

    const res = await response.json()
    const { transaction } = res
    return {
      data: transaction.data,
      to: '0x2d8879046f1559E53eb052E949e9544bCB72f414',
    }
  }, [bestTrade, account])
}

export const useFusionTxData = () => {
  const { account, library } = useContext(Web3Context).activeWeb3React
  const { toAsset, fromAsset, fromAmount, slippage, deadline } = useConfirmationContext()
  const timestamp = Math.floor(new Date().getTime() / 1000) + deadline * 60
  const outCurrency = useCurrency(toAsset ? toAsset.address : undefined)
  const inCurrency = useCurrency(fromAsset ? fromAsset.address : undefined)
  const fromAmountUi = fromWei(fromAmount || '0', fromAsset?.decimals || 0).toString(10)
  const parsedAmount = tryParseAmount(fromAmountUi, inCurrency)
  const bestV3TradeExactIn = useBestV3TradeExactIn(parsedAmount, outCurrency)
  const allowedSlippage = new Percent(JSBI.BigInt(parseInt(slippage) * 100), JSBI.BigInt(10000))
  const swapCalls = useSwapCallArguments(bestV3TradeExactIn?.trade, allowedSlippage, account, timestamp)

  return useCallback(async () => {
    const estimatedCalls = await Promise.all(
      swapCalls.map((call) => {
        const { address, calldata, value } = call

        const tx =
          !value || isZero(value)
            ? { from: account, to: address, data: calldata }
            : {
                from: account,
                to: address,
                data: calldata,
                value,
              }

        return library
          .estimateGas(tx)
          .then((gasEstimate) => {
            return {
              call,
              gasEstimate,
            }
          })
          .catch(() => {
            console.log('Gas estimate failed, trying eth_call to extract error', call)

            return library
              .call(tx)
              .then((error) => {
                console.log({ error })
                return {
                  call,
                  error: new Error('Unexpected issue with estimating the gas. Please try again.'),
                }
              })
              .catch((callError) => {
                return {
                  call,
                  error: new Error(callError),
                }
              })
          })
      }),
    )

    // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
    let bestCallOption = estimatedCalls.find((el, ix, list) => 'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1]))
    // check if any calls errored with a recognizable error
    if (!bestCallOption) {
      const errorCalls = estimatedCalls.filter((call) => 'error' in call)
      if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
      const firstNoErrorCall = estimatedCalls.find((call) => !('error' in call))
      if (!firstNoErrorCall) throw new Error('Unexpected error. Could not estimate gas for the swap.')
      bestCallOption = firstNoErrorCall
    }
    const {
      call: { calldata },
    } = bestCallOption

    return {
      data: calldata,
      to: getFusionRouterAddress(),
    }
  }, [account, library, swapCalls])
}

export const useOpenOceanTxData = () => {
  const { account } = useWeb3Wagmi()
  const gasPrices = useGasPrice()
  const { fromAsset, toAsset, fromAmount, slippage } = useConfirmationContext()

  return useCallback(async () => {
    const zeroAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
    const inTokenAddress = fromAsset.address === 'ETH' ? zeroAddress : fromAsset.address
    const outTokenAddress = toAsset.address === 'ETH' ? zeroAddress : toAsset.address
    const response = await fetch(
      `https://open-api.openocean.finance/v3/linea/swap_quote?inTokenAddress=${inTokenAddress}&outTokenAddress=${outTokenAddress}&amount=${BN(fromAmount)
        .dp(fromAsset.decimals)
        .toString(10)}&gasPrice=${formatGwei(gasPrices.fast)}&slippage=${slippage}&account=${account}&enabledDexIds=${EnabledDexIds}
      &referrer=0xDb73ba19F072D0Fbc865781Ba468A9F8B77aD2C4&connectors=${Connectors}`,
      {
        method: 'GET',
      },
    )
    const res = await response.json()
    const { data } = res.data

    return {
      data: data,
      to: getOpenOceanRouterAddress(),
    }
  }, [account, fromAmount, fromAsset, toAsset, slippage, gasPrices])
}

export const useKyberTxData = () => {
  const { trade, account, slippage } = useConfirmationContext()
  return useCallback(async () => {
    const payload = {
      routeSummary: trade.routeSummary,
      sender: account,
      recipient: account,
      slippageTolerance: slippage * 100,
    }
    const response = await fetch(`${KYBER_API}/route/build`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    const res = await response.json()
    const { data } = res
    return {
      data: data,
      to: trade.routerAddress,
    }
  }, [trade, account, slippage])
}

const useGetTxData = () => {
  const { bestTrade } = useConfirmationContext()

  const getFusionTxData = useFusionTxData()
  const getOdosoTxData = useOdosTxData()
  const getKyberTxData = useKyberTxData()
  const getOpenOceanTxData = useOpenOceanTxData()
  return useCallback(async () => {
    if (!bestTrade) {
      throw new Error('No best trade found')
    }
    try {
      switch (bestTrade.name.toLowerCase()) {
        case 'fusion':
          return await getFusionTxData()
        case 'odos':
          return await getOdosoTxData()
        case 'kyber':
          return await getKyberTxData()
        case 'openocean':
          return await getOpenOceanTxData()
        default:
          return undefined
      }
    } catch (error) {
      console.log('Error getting tx data', error)
    }
  }, [bestTrade, getFusionTxData, getOdosoTxData, getKyberTxData, getOpenOceanTxData])
}

const useHasAllowanceCallback = () => {
  const web3 = useWeb3()
  const { account } = useWeb3Wagmi()
  const { fromAsset, fromAmount } = useConfirmationContext()
  const wToken = useWrappedToken()

  return useCallback(async () => {
    const tokenContract = getERC20Contract(web3, fromAsset.symbol === 'ETH' ? wToken.address : fromAsset.address)
    const allowance = await getAllowance(tokenContract, permit2Address, account)
    return BN(allowance).gte(fromAmount)
  }, [web3, account, fromAsset, fromAmount])
}
const useSwapCallback = () => {
  const web3 = useWeb3()
  const { updateStore, fromAsset, quote, bestTrade, setFromAmount, refetchLiquidityHubQuote, onFailed } = useConfirmationContext()
  const { account } = useWeb3Wagmi()
  const wToken = useWrappedToken()
  const approveCallback = useApproveCallback()
  const wrapCallback = useWrapCallback()
  const signEIP712Callback = useSignEIP712Callback()
  const sdk = useLiquidityHubSDK()
  const hasAllowanceCallback = useHasAllowanceCallback()
  const getTxData = useGetTxData()
  const inCurreny = useCurrency(fromAsset?.address)

  return useCallback(async () => {
    let wrappedSuccess = false

    try {
      let steps = []
      updateStore({ swapStatus: SwapStatus.LOADING, wrappedSuccess })
      const hasAllowance = await hasAllowanceCallback()
      if (inCurreny.isNative) {
        steps.push(Steps.WRAP)
      }
      if (!hasAllowance) {
        steps.push(Steps.APPROVE)
      }
      steps.push(Steps.SWAP)
      updateStore({ steps })

      if (inCurreny.isNative) {
        updateStore({ currentStep: Steps.WRAP })
        await wrapCallback()
        wrappedSuccess = true
      }

      if (!hasAllowance) {
        updateStore({ currentStep: Steps.APPROVE })
        await approveCallback()
      }
      updateStore({ currentStep: Steps.SWAP })

      const latestQuote = await refetchLiquidityHubQuote()
      const signature = await signEIP712Callback(latestQuote.permitData)
      updateStore({ signature })
      const txData = await getTxData()
      const txHash = await sdk.swap(latestQuote, signature, txData)
      const result = await sdk.getTransactionDetails(txHash, latestQuote)
      updateStore({ txHash, swapStatus: SwapStatus.SUCCESS, exactOutAmount: result.exactOutAmount })
      setFromAmount('')
      return result
    } catch (error) {
      if (isRejectedError(error) && !wrappedSuccess) {
        updateStore({ swapStatus: undefined })
      } else {
        updateStore({ swapStatus: SwapStatus.FAILED })
        onFailed()
      }
    } finally {
      updateStore({ currentStep: undefined, steps: undefined, wrappedSuccess })
    }
  }, [
    account,
    approveCallback,
    hasAllowanceCallback,
    quote,
    signEIP712Callback,
    sdk,
    updateStore,
    wrapCallback,
    wToken,
    web3,
    getTxData,
    bestTrade,
    setFromAmount,
    refetchLiquidityHubQuote,
    onFailed,
    inCurreny,
  ])
}

const initialState = {
  swapStatus: undefined,
  currentStep: undefined,
  shouldUnwrap: undefined,
  txHash: undefined,
  steps: undefined,
  error: undefined,
  stapStatus: undefined,
  signature: undefined,
  exactOutAmount: undefined,
  wrappedSuccess: false,
}

function reducer(state, action, initialState) {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

const useStepsStore = () => {
  const [store, dispatch] = useReducer((state, action) => reducer(state, action, initialState), initialState)

  const updateStore = useCallback(
    (payload) => {
      dispatch({ type: 'UPDATE_STATE', payload })
    },
    [dispatch],
  )

  const resetStore = useCallback(() => {
    dispatch({ type: 'RESET' })
  }, [dispatch])

  return {
    store,
    updateStore,
    resetStore,
  }
}

const useWrappedToken = () => {
  const baseAssets = useContext(BaseAssetsConetext)

  // should be mapped by chain id, in case we will support multiple chains
  const result = useMemo(() => {
    return baseAssets.find((asset) => asset.symbol === 'WETH')
  }, [baseAssets])

  return result
}

const useNativeToken = () => {
  const baseAssets = useContext(BaseAssetsConetext)

  // should be mapped by chain id, in case we will support multiple chains
  const result = useMemo(() => {
    return baseAssets.find((asset) => asset.symbol === 'ETH')
  }, [baseAssets])

  return result
}
const Context = createContext()

const useConfirmationContext = () => {
  return useContext(Context)
}

export const LiquidityHubConfirmationModal = ({
  quote,
  isOpen,
  closeConfirmationModal,
  fromAsset,
  toAsset,
  slippage,
  bestTrade: _bestTrade,
  deadline,
  setFromAmount,
  refetchLiquidityHubQuote,
  onFailed,
}) => {
  const [bestTrade, setBestTrade] = useState(_bestTrade)
  const { store, updateStore, resetStore } = useStepsStore()

  useEffect(() => {
    if (_bestTrade) {
      setBestTrade(_bestTrade)
    }
  }, [_bestTrade])

  const closeModal = useCallback(() => {
    closeConfirmationModal()
    resetStore()
  }, [closeConfirmationModal, resetStore])

  return (
    <Context.Provider
      value={{
        quote,
        isOpen,
        closeModal,
        fromAsset,
        toAsset,
        store,
        updateStore,
        slippage,
        bestTrade,
        deadline,
        setFromAmount,
        fromAmount: quote?.inAmount,
        refetchLiquidityHubQuote,
        onFailed,
      }}
    >
      <ConfirmationModalContent />
    </Context.Provider>
  )
}

const ConfirmationModalContent = () => {
  const { store, quote, isOpen, closeModal, fromAsset, toAsset } = useConfirmationContext()
  const onSwap = useSwapCallback()
  const outAmountUi = fromWei(store?.exactOutAmount || quote?.referencePrice || '0', toAsset?.decimals || 0).toString(10)
  const fromAmountUi = fromWei(quote?.inAmount || '0', fromAsset?.decimals || 0).toString(10)

  const { inToken, outToken } = useMemo(() => {
    return {
      inToken: {
        symbol: fromAsset?.symbol,
        logo: fromAsset?.logoURI,
      },
      outToken: {
        symbol: toAsset?.symbol,
        logo: toAsset?.logoURI,
      },
    }
  }, [fromAsset, toAsset])

  const parsedSteps = useParseSteps()
  const fromUsd = useMemo(() => formatAmount((fromAsset?.price || 0) * (Number(fromAmountUi) || 0)), [fromAsset?.price, fromAmountUi])
  const toUsd = useMemo(() => {
    return formatAmount((toAsset?.price || 0) * (Number(fromWei(quote?.referencePrice || '0', toAsset?.decimals || 0).toString(10)) || 0))
  }, [toAsset?.price, quote?.referencePrice, toAsset?.decimals])

  return (
    <Modal popup={isOpen} disableOutside={true} setPopup={closeModal} title={!store.swapStatus ? 'Submit Swap' : ''} isToken={false}>
      <StyledModalContainer className='w-full'>
        <SwapFlow
          inAmount={!fromAmountUi ? '' : formatAmount(fromAmountUi).toString()}
          outAmount={!outAmountUi ? '' : formatAmount(outAmountUi).toString()}
          inToken={inToken}
          outToken={outToken}
          swapStatus={store.swapStatus}
          successContent={<SuccessContent />}
          failedContent={<ErrorContent />}
          mainContent={
            <SwapFlow.Main
              inUsd={fromUsd && `$${fromUsd}`}
              outUsd={toUsd && `$${toUsd}`}
              fromTitle={'Sell'}
              toTitle={'Buy'}
              steps={parsedSteps}
              currentStep={store.currentStep}
              showSingleStep={false}
            />
          }
        />
        {!store.swapStatus && (
          <BottomContent>
            <GasFee toAsset={toAsset} gasFee={quote?.gasAmountOut} />
            <StyledSubmitSwapButton onClickHandler={onSwap} content={'Swap'} />
            <GasslessText>Gassless transaction powered by Orbs</GasslessText>
          </BottomContent>
        )}
      </StyledModalContainer>
    </Modal>
  )
}

const useParseSteps = () => {
  const {
    store: { steps, signature },
    fromAsset,
  } = useConfirmationContext()
  const nativeToken = useNativeToken()
  return useMemo(() => {
    return steps?.map((step) => {
      if (step === Steps.WRAP) {
        return {
          title: `Wrap ${nativeToken?.symbol}`,
          icon: <img src={nativeToken.logoURI} />,
          id: Steps.WRAP,
        }
      }
      if (step === Steps.APPROVE) {
        return {
          title: `Approve ${fromAsset?.symbol} spending`,
          icon: <MdCheckCircle />,
          id: Steps.APPROVE,
        }
      }

      return {
        id: Steps.SWAP,
        title: !signature ? 'Confirm swap' : 'Swap pending...',
        icon: <MdOutlineSwapVert />,
        timeout: !signature ? 60_000 : 90_000,
      }
    })
  }, [steps, fromAsset, signature, nativeToken])
}

const ErrorContent = () => {
  const {
    store: { wrappedSuccess },
  } = useConfirmationContext()
  const native = useNativeToken()
  const wrapped = useWrappedToken()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <MdOutlineError color='#ff3333' size='70px' />
      <p style={{ fontSize: 22 }}>Transaction failed</p>
      {wrappedSuccess && (
        <p style={{ fontSize: 16, opacity: 0.7 }}>
          Notice: {native?.symbol} was wrapped to {wrapped?.symbol}
        </p>
      )}
    </div>
  )
}

const SuccessContent = () => {
  return <SwapFlow.Success title='Swap success' />
}

const GasslessText = styled('p')({
  fontSize: 14,
  color: 'rgb(218, 218, 218)',
  opacity: 0.8,
})

const BottomContent = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  width: '100%',
})

const GasFee = ({ toAsset, gasFee }) => {
  const feeUi = useMemo(() => {
    if (!toAsset || !gasFee) return ''
    return fromWei(gasFee, toAsset?.decimals).toString(10)
  }, [toAsset, gasFee])

  const feeUsd = useMemo(() => {
    if (!toAsset || !feeUi) return ''
    return formatAmount((toAsset?.price || 0) * (Number(feeUi) || 0))
  }, [toAsset, feeUi])

  return (
    <StyledGasFee>
      <p>Network cost</p>
      <p>${feeUsd}</p>
    </StyledGasFee>
  )
}

const StyledGasFee = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  color: 'rgb(218, 218, 218)',
  opacity: 0.8,
  fontSize: '14px',
})

const getDexMinAmountOut = (dexOutAmount, slippage) => {
  if (!dexOutAmount) return undefined
  if (!slippage) return dexOutAmount

  return BN(dexOutAmount)
    .times(100 - slippage)
    .div(100)
    .decimalPlaces(0)
    .toFixed()
}

const Routing = ({ token }) => {
  return (
    <div
      className={`relative flex py-4 px-4 after:w-[60px] before:absolute before:left-0 before:top-0 before:w-full before:h-[48px] before:rounded-b-[24px] before:border-dashed before:border-[#DEDBF2] before:border-b before:border-x`}
    >
      <div className='py-2 px-3 rounded-xl bg-[#3d3d3d] border border-[#ffffff33] relative text-white text-base w-fit h-fit mt-[10px]'>100%</div>
      <div className='relative flex grow px-3'>
        <div className='w-full flex justify-between overflow-hidden space-x-4 before:content-[""] after:content-[""]'>
          <div className='px-3 py-2 bg-[#3d3d3d] border border-[#ffffff33] rounded-xl w-fit space-y-1'>
            <div className='flex items-center justify-around py-[7px] space-x-[6px]'>
              {token.logoURI ? (
                <img src={token?.logoURI} alt='' className='w-[22px] h-[22px]' />
              ) : (
                <span className='text-base md:text-[18px] text-white'>{token?.symbol}</span>
              )}
            </div>
            <div className='text-[12px] md:text-[14px] text-white pl-1 space-x-1'>
              <span>Liquidity Hub</span>
              <span>100%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Settings = () => {
  const [enabled, setEnabled] = useState(true)
  return (
    <>
      <div className='flex items-center justify-between w-full mt-4'>
        <div className='flex items-center space-x-1.5'>
          <p className='text-sm font-medium text-white'>Disable Liquidity Hub</p>
        </div>
        <Toggle checked={!enabled} onChange={() => setEnabled(!enabled)} toggleId='liquifity-hub-toggle' />
      </div>
      <StyledSettingsText className='text-sm text-white'>
        <a href={`${WEBSITE_URL}/liquidity-hub`} rel='noreferrer' target='_blank'>
          {' '}
          Liquidity Hub
        </a>
        , powered by{' '}
        <a href={WEBSITE_URL} rel='noreferrer' target='_blank'>
          Orbs
        </a>
        , may provide better price by aggregating liquidity from multiple sources.{' '}
        <a href={`${WEBSITE_URL}/liquidity-hub`} rel='noreferrer' target='_blank'>
          For more info.
        </a>
      </StyledSettingsText>
    </>
  )
}

const StyledSettingsText = styled.p`
  a {
    text-decoration: underline;
  }
`

const StyledPoweredByOrbs = styled(PoweredByOrbs)`
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
  img {
    min-width: 24px;
    min-height: 24px;
  }
`

const StyledModalContainer = styled.div`
  padding-top: 20px;
  color: #fff;
  .lh-step-loader {
    background-color: #3D3B3A;
  }
  a {
    color: rgb(223 131 47/1);
    text-decoration: none;
  }
  .orbs_StepsStepLogo {
    background-color: #2C2C2C;
    border: 7px solid #2C2C2C;
  }
  
  .orbs_StepsStep {
    gap: 10px;
  }
    .orbs_Steps {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
    .orbs_StepsStepLeft {
      flex: 1;
    }
  }
`

const StyledSubmitSwapButton = styled(StyledButton)`
  padding: 15px 0px;
  width: 100%;
`

export const LiquidityHub = {
  PoweredByOrbs: StyledPoweredByOrbs,
  Routing,
  Settings,
  useLiquidityHubQuoteCallback,
  getDexMinAmountOut,
  getBetterPrice,
}
