/* global gtag */
import { useEffect, useState, useRef } from 'react'
import Web3 from 'web3'
import { defaultHttpProvider, getWeb3NoAccount } from '../utils/web3'
import { useAccount, useWalletClient } from 'wagmi'
import { getIdentity } from '../utils/api'

const useWeb3 = () => {
  const library = useGetProvider()
  const refEth = useRef(library)
  const [web3, setweb3] = useState(library ? new Web3(library) : getWeb3NoAccount())
  const { address: account, isConnected } = useAccount()
  const refAccount = useRef(account)

  useEffect(() => {
    if (library !== refEth.current) {
      setweb3(library ? new Web3(library) : getWeb3NoAccount())
      refEth.current = library
    }
    if (account !== refAccount.current && isConnected) {
      refAccount.current = account
      console.log('wallet connected', account)
      gtag('event', 'wallet_connected', {
        userAppWalletId: `acc-${account}`,
        onChainId: account.substring(2),
      })
      gtag('set', 'user_id', account.substring(2))
      gtag('set', 'user_properties', {
        onChainId: account.substring(2),
      })
    }
  }, [library, account])
  return web3
}
const useGetProvider = () => {
  const { connector } = useAccount()
  const [provider, setProvider] = useState(defaultHttpProvider)

  useEffect(() => {
    const fetchProvider = async () => {
      const provider = await connector.getProvider()
      if (provider) setProvider(provider)
    }

    if (connector) fetchProvider()
  }, [connector, setProvider])

  return provider
}

export const useWeb3Wagmi = () => {
  const { address: account, isConnected } = useAccount()
  const { data: walletClient } = useWalletClient()
  const library = useGetProvider()

  useEffect(() => {
    const setIdentity = async () => {
      const fetchIdentity = await getIdentity(account)
      if (fetchIdentity.identity) {
        const identity = fetchIdentity.identity
        window.THXWidget.setIdentity(identity)
      }
    }
    if (account && isConnected && window.THXWidget?.iframe) {
      setIdentity()
    }
  }, [account, isConnected, window.THXWidget?.iframe])

  return { account, walletClient, library, chainId: walletClient?.chain.id, active: isConnected }
}

export default useWeb3
