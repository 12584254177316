import React, { useState } from 'react'
import Slider from 'react-rangeslider'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import 'react-rangeslider/lib/index.css'
import { formatAmount } from '../../../../../../utils/formatNumber'
import { customNotify } from '../../../../../../utils/notify'
import { useGammaRemove } from '../../../../../../hooks/v3/useGamma'
import useDebounce from '../../../../../../hooks/useDebounce'
import { useIchiRemove } from '../../../../../../hooks/v3/useIchi'
import { useSteerRemove } from '../../../../../../hooks/v3/useSteer'
import { useDefiedgeRemove } from '../../../../../../hooks/v3/useDefiedge'
import { useClipFinanceAdd } from '../../../../../../hooks/v3/useClipFinance'

const percentArray = [25, 50, 75, 100]

const GammaRemoveModal = ({ isOpen, setIsOpen, position }) => {
  const [percent, setPercent] = useState(0)
  const debouncedPercent = useDebounce(percent)
  const { address: gammaAddress, token0, token1, account, type, strategist } = position
  const { pending: pendingGamma, onGammaRemove } = useGammaRemove()
  const { pending: pendingIchi, onIchiRemove } = useIchiRemove()
  const { pending: pendingSteer, onSteerRemove } = useSteerRemove()
  const { pending: pendingDefiEdge, handleDefiEdgeRemove: onDefiEdgeRemove } = useDefiedgeRemove()
  const { pending: pendingClipFinance, onClipRemove } = useClipFinanceAdd()
  const onRemove = () => {
    if (debouncedPercent > 0) {
      setIsOpen(false)
      if (strategist === 'Ichi') {
        onIchiRemove(gammaAddress, token0.symbol, token1.symbol, account.lpBalance.times(debouncedPercent).div(100), position)
      } else if (strategist === 'Steer') onSteerRemove(gammaAddress, token0.symbol, token1.symbol, account.lpBalance.times(debouncedPercent).div(100), position)
      else if (strategist === 'Defiedge')
        onDefiEdgeRemove(gammaAddress, token0.symbol, token1.symbol, account.lpBalance.times(debouncedPercent).div(100), position)
      else if (strategist === 'Clip Finance')
        onClipRemove(gammaAddress, token0.symbol, token1.symbol, account.lpBalance.times(debouncedPercent).div(100), position)
      else onGammaRemove(gammaAddress, token0.symbol, token1.symbol, account.lpBalance.times(debouncedPercent).div(100), position)
    } else {
      customNotify('Invalid Amount', 'warn')
    }
  }

  const pending = pendingIchi && pendingGamma && pendingSteer && pendingDefiEdge && pendingClipFinance

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Withdraw Liquidity'} width={548}>
      <div className='px-4 pt-5 pb-3 mt-[13px]'>
        <div className='flex items-start md:items-center justify-between'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0.logoURI || '/images/tokens/UKNOWN.png'} />
              <img alt='' className='w-6 lg:w-[30px] -ml-3' src={token1.logoURI || '/images/tokens/UKNOWN.png'} />
            </div>
            <p className='text-[13px] md:text-lg f-f-fg font-semibold text-white'>
              {token0.symbol}/{token1.symbol}
            </p>
          </div>
          <div className='bg-white/10 py-1 pl-3 mt-2 md:mt-0 rounded-md flex items-center space-x-[5px] pr-4 flex-shrink-0'>
            <span className='text-xs md:text-sm fonts-medium text-white whitespace-nowrap'>{type} range</span>
          </div>
        </div>
        <div className='flex items-center mt-2'>
          <span className='text-xs md:text-sm text-gray-400'>Managed by {strategist}</span>
        </div>
        <div className='mt-5 flex flex-col justify-start space-y-1 md:flex-row md:items-center md:justify-between'>
          <span className='text-[#E0DEF0] text-sm md:text-base leading-5'>Amount</span>
          <div className='flex items-center space-x-1 md:space-x-2'>
            {percentArray.map((perc, index) => {
              return (
                <div
                  className={`flex items-center justify-center bg-white/5 rounded-md text-white text-sm md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                  onClick={() => setPercent(perc)}
                  key={`percent-${index}`}
                >
                  {perc}%
                </div>
              )
            })}
          </div>
        </div>
        <div className='w-full pt-4 md:pt-5 pb-5 md:pb-[22px] mt-1 md:mt-[7px]'>
          <div className='text-lg md:text-2xl leading-5 md:leading-[29px] text-white'>{debouncedPercent}%</div>
          <Slider
            min={0}
            max={100}
            className='!mt-3 mb-0'
            value={percent}
            onChange={(value) => {
              setPercent(value)
            }}
          />
        </div>
        <div className='mt-3 w-full'>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
            <div className='flex items-center space-x-[5px]'>
              <img alt='' src={token0.logoURI} className={'w-4 md:w-5'} />
              <span>{token0.symbol}</span>
            </div>
            <span>{formatAmount(account.total0.minus(account.staked0).times(debouncedPercent).div(100))}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <div className='flex items-center space-x-[5px]'>
              <img alt='' src={token1.logoURI} className={'w-4 md:w-5'} />
              <span>{token1.symbol}</span>
            </div>
            <span>{formatAmount(account.total1.minus(account.staked1).times(debouncedPercent).div(100))}</span>
          </div>
        </div>
        <StyledButton onClickHandler={onRemove} disabled={pending} content={'Withdraw'} className='py-2 mt-4 text-sm md:text-lg px-4 w-full' />
      </div>
    </Modal>
  )
}

export default GammaRemoveModal
