/* eslint-disable no-unused-vars */
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BigNumber from 'bignumber.js'
import { v4 as uuidv4 } from 'uuid'
import { routerAbi } from '../config/abi'
import { defaultChainId, routeAssets, TaxAssets, TransactionType } from '../config/constants'
import { getOpenOceanRouterAddress, getRouterAddress } from '../utils/addressHelpers'
import { formatAmount, fromWei, isInvalidAmount, MAX_UINT256, toWei, wrappedAsset } from '../utils/formatNumber'
import { multicallChunk } from '../utils/multicall'
import { getAllowance, sendContract, sendV3Contract } from '../utils/api'
import { useRouter } from './useContract'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { getERC20Contract, getWBNBContract } from '../utils/contractHelpers'
import moment from 'moment'
import useRefresh from './useRefresh'
import { FusionsContext } from '../context/FusionsContext'
import { useGasPrice } from './useGeneral'
import { tryParseAmount } from '../v3lib/utils/utils'
import { useBestV3TradeExactIn } from './v3/useBestV3Trade'
import { useCurrency } from './v3/Tokens'
import { Percent } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { useSwapCallback } from './v3/useSwapCallback'
import Web3 from 'web3'
import { trackSwap } from '../utils/tracking'
import { formatGwei } from 'viem'

export const EnabledDexIds = '19,18'
export const Connectors =
  '0x1a51b19CE03dbE0Cb44C1528E34a7EDD7771E9Af,0x63349BA5E1F71252eCD56E8F950D1A518B400b60,0xb79dd08ea68a908a97220c76d19a6aa9cbde4376,0x11d8680c7f8f82f623e840130eb06c33d9f90c89,0xB5beDd42000b71FddE22D3eE8a79Bd49A568fC8F'
export const KYBER_API = 'https://aggregator-api.kyberswap.com/linea/api/v1'
export const ODOS_API = 'https://api.odos.xyz/sor/quote/v2'
export const ODOS_ASSEMBLE = 'https://api.odos.xyz/sor/assemble'
export const ODOS_SOURCES = ['Wrapped Ether', 'Lynex Fusion', 'Lynex V1 Volatile', 'Lynex V1 Stable', 'Overnight Exchange']

const useQuoteSwap = (fromAsset, toAsset, fromAmount, multihops = true) => {
  const [bestTrade, setBestTrade] = useState()
  const [priceImpact, setPriceImpact] = useState(new BigNumber(0))
  const [quotePending, setQuotePending] = useState(false)
  const fusions = useContext(FusionsContext)

  useEffect(() => {
    const fetchInfo = async () => {
      const bases = routeAssets[defaultChainId].filter(
        (item) => ![fromAsset.address.toLowerCase(), toAsset.address.toLowerCase()].includes(item.address.toLowerCase()),
      )
      const result = []
      // direct pairs
      result.push({
        routes: [
          {
            from: fromAsset.address,
            to: toAsset.address,
            stable: true,
          },
        ],
      })
      result.push({
        routes: [
          {
            from: fromAsset.address,
            to: toAsset.address,
            stable: false,
          },
        ],
      })
      if (multihops) {
        // 1 hop
        bases.forEach((base) => {
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: true,
              },
              {
                from: base.address,
                to: toAsset.address,
                stable: true,
              },
            ],
            base: [base],
          })
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: true,
              },
              {
                from: base.address,
                to: toAsset.address,
                stable: false,
              },
            ],
            base: [base],
          })
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: false,
              },
              {
                from: base.address,
                to: toAsset.address,
                stable: true,
              },
            ],
            base: [base],
          })
          result.push({
            routes: [
              {
                from: fromAsset.address,
                to: base.address,
                stable: false,
              },
              {
                from: base.address,
                to: toAsset.address,
                stable: false,
              },
            ],
            base: [base],
          })
        })
        // 2 hop
        bases.forEach((base) => {
          const otherbases = bases.filter((ele) => ele.address !== base.address)
          otherbases.forEach((otherbase) => {
            // true true true
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: true,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: true,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: true,
                },
              ],
              base: [base, otherbase],
            })
            // true true false
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: true,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: true,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: false,
                },
              ],
              base: [base, otherbase],
            })
            // true false true
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: true,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: false,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: true,
                },
              ],
              base: [base, otherbase],
            })
            // true false false
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: true,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: false,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: false,
                },
              ],
              base: [base, otherbase],
            })
            // false true true
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: false,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: true,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: true,
                },
              ],
              base: [base, otherbase],
            })
            // false true false
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: false,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: true,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: false,
                },
              ],
              base: [base, otherbase],
            })
            // false false true
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: false,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: false,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: true,
                },
              ],
              base: [base, otherbase],
            })
            // false false false
            result.push({
              routes: [
                {
                  from: fromAsset.address,
                  to: base.address,
                  stable: false,
                },
                {
                  from: base.address,
                  to: otherbase.address,
                  stable: false,
                },
                {
                  from: otherbase.address,
                  to: toAsset.address,
                  stable: false,
                },
              ],
              base: [base, otherbase],
            })
          })
        })
      }
      const final = result.filter((item) => {
        let isExist = true
        for (let i = 0; i < item.routes.length; i++) {
          const route = item.routes[i]
          const found = fusions
            .filter((pair) => !pair.isGamma)
            .find((pair) =>
              route.stable
                ? pair.type === 'Stable'
                : pair.type === 'Volatile' &&
                  [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.from.toLowerCase()) &&
                  [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.to.toLowerCase()),
            )
          if (!found) {
            isExist = false
            break
          }
        }
        return isExist
      })
      if (final.length === 0) {
        setBestTrade(null)
        return
      }
      setQuotePending(true)
      const sendFromAmount = toWei(fromAmount, fromAsset.decimals).toFixed(0)
      const calls = final.map((item) => {
        return {
          address: getRouterAddress(),
          name: 'getAmountsOut',
          params: [sendFromAmount, item.routes],
        }
      })
      try {
        const receiveAmounts = await multicallChunk(routerAbi, calls, 50)

        for (let i = 0; i < receiveAmounts.length; i++) {
          final[i].receiveAmounts = receiveAmounts[i].amounts
          final[i].finalValue = fromWei(Number(receiveAmounts[i].amounts[receiveAmounts[i].amounts.length - 1]), toAsset.decimals)
        }
        const bestAmountOut = final.reduce((best, current) => {
          if (!best) {
            return current
          }
          return best.finalValue.gt(current.finalValue) ? best : current
        }, 0)

        if (bestAmountOut.finalValue.isZero()) {
          setQuotePending(false)
          setBestTrade(null)
          return
        }

        let totalRatio = new BigNumber(1)

        for (let i = 0; i < bestAmountOut.routes.length; i++) {
          const route = bestAmountOut.routes[i]
          if (!route.stable) {
            const found = fusions.find(
              (pair) =>
                pair.stable === route.stable &&
                !pair.isGamma &&
                [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.from.toLowerCase()) &&
                [pair.token0.address.toLowerCase(), pair.token1.address.toLowerCase()].includes(route.to.toLowerCase()),
            )
            let reserveIn
            let reserveOut
            if (found.token0.address.toLowerCase() === route.from.toLowerCase()) {
              reserveIn = toWei(found.token0.reserve, found.token0.decimals)
              reserveOut = toWei(found.token1.reserve, found.token1.decimals)
            } else {
              reserveIn = toWei(found.token1.reserve, found.token1.decimals)
              reserveOut = toWei(found.token0.reserve, found.token0.decimals)
            }
            let amountIn = 0
            let amountOut = 0
            if (i == 0) {
              amountIn = sendFromAmount
              amountOut = Number(bestAmountOut.receiveAmounts[1])
            } else {
              amountIn = Number(bestAmountOut.receiveAmounts[i])
              amountOut = Number(bestAmountOut.receiveAmounts[i + 1])
            }

            const amIn = new BigNumber(amountIn).div(reserveIn)
            const amOut = new BigNumber(amountOut).div(reserveOut)
            const ratio = new BigNumber(amOut).div(amIn)

            totalRatio = totalRatio.times(ratio)
          }
        }

        setBestTrade({
          ...bestAmountOut,
          outputAmount: bestAmountOut?.finalValue?.toNumber(),
          outAmount: toWei(bestAmountOut?.finalValue?.toNumber(), toAsset?.decimals)?.toString(),
          name: 'Classic',
        })
        setPriceImpact(new BigNumber(1).minus(totalRatio).times(100))
        setQuotePending(false)
      } catch (error) {
        console.log('error :>> ', error)
        setBestTrade(null)
        setQuotePending(false)
      }
    }
    if (fromAsset && toAsset && !isInvalidAmount(fromAmount) && fromAsset.address.toLowerCase() !== toAsset.address.toLowerCase()) {
      fetchInfo()
    } else {
      setBestTrade(null)
    }
  }, [fromAsset, toAsset, fromAmount, fusions, multihops])

  return { bestTrade, priceImpact, quotePending }
}

const useProceedSwap = () => {
  const [swapPending, setSwapPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const routerAddress = getRouterAddress()
  const routerContract = useRouter()
  const web3 = useWeb3()

  const handleSwap = useCallback(
    async (fromAsset, toAsset, fromAmount, bestTrade, slippage, deadline) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const swapuuid = uuidv4()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Swap ${fromAsset.symbol} for ${toAsset.symbol}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${fromAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [swapuuid]: {
              desc: `Swap ${formatAmount(fromAmount)} ${fromAsset.symbol} for ${toAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (fromAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, fromAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, fromAsset.decimals).lt(fromAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setSwapPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const sendSlippage = new BigNumber(100).minus(slippage).div(100)
      const sendFromAmount = toWei(fromAmount, fromAsset.decimals).toFixed(0)
      const sendMinAmountOut = toWei(bestTrade.finalValue.times(sendSlippage), toAsset.decimals).toFixed(0)
      const deadlineVal =
        '' +
        moment()
          .add(Number(deadline) * 60, 'seconds')
          .unix()

      let func =
        TaxAssets.includes(fromAsset.address.toLowerCase()) || TaxAssets.includes(toAsset.address.toLowerCase())
          ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens'
          : 'swapExactTokensForTokens'
      let params = [sendFromAmount, sendMinAmountOut, bestTrade.routes, account, deadlineVal]
      let sendValue = '0'

      if (fromAsset.address === 'ETH') {
        if (TaxAssets.includes(toAsset.address.toLowerCase())) {
          func = 'swapExactETHForTokensSupportingFeeOnTransferTokens'
        } else {
          func = 'swapExactETHForTokens'
        }
        params = [sendMinAmountOut, bestTrade.routes, account, deadlineVal]
        sendValue = sendFromAmount
      }
      if (toAsset.address === 'ETH') {
        if (TaxAssets.includes(fromAsset.address.toLowerCase())) {
          func = 'swapExactTokensForETHSupportingFeeOnTransferTokens'
        } else {
          func = 'swapExactTokensForETH'
        }
      }
      try {
        await sendContract(dispatch, key, swapuuid, routerContract, func, params, account, sendValue)
      } catch (err) {
        console.log('swap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Swap Successful',
        }),
      )
      trackSwap({
        eventName: 'swap',
        walletAddress: account,
        fromAmount: Number(fromAmount),
        fromCurrency: fromAsset.symbol,
        toAmount: fromWei(sendMinAmountOut).toNumber(),
        toCurrency: toAsset.symbol,
        contractAddress: routerAddress,
        amountUSD: Number(fromAmount) * fromAsset.price,
      })
      setSwapPending(false)
    },
    [account, web3, routerContract],
  )

  const handleWrap = useCallback(
    async (amount) => {
      const key = uuidv4()
      const wrapuuid = uuidv4()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Wrap ETH for WETH`,
          transactions: {
            [wrapuuid]: {
              desc: `Wrap ${formatAmount(amount)} ETH for WETH`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      const wbnbContract = getWBNBContract(web3)
      try {
        await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, toWei(amount).toFixed(0))
      } catch (err) {
        console.log('wrap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Wrap Successful',
        }),
      )
      setSwapPending(false)
    },
    [account, web3, routerContract],
  )

  const handleUnwrap = useCallback(
    async (amount) => {
      const key = uuidv4()
      const wrapuuid = uuidv4()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Unwrap WETH for ETH`,
          transactions: {
            [wrapuuid]: {
              desc: `Unwrap ${formatAmount(amount)} WETH for ETH`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      const wbnbContract = getWBNBContract(web3)
      try {
        await sendContract(dispatch, key, wrapuuid, wbnbContract, 'withdraw', [toWei(amount).toFixed(0)], account)
      } catch (err) {
        console.log('unwrap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Unwrap Successful',
        }),
      )
      setSwapPending(false)
    },
    [account, web3, routerContract],
  )

  return { onSwap: handleSwap, onWrap: handleWrap, onUnwrap: handleUnwrap, swapPending }
}

const useOpenOceanQuoteSwap = (fromAsset, toAsset, fromAmount, slippage) => {
  const [bestTrade, setBestTrade] = useState()
  const [quotePending, setQuotePending] = useState(false)
  const { fastRefresh } = useRefresh()
  const gasPrices = useGasPrice()

  useEffect(() => {
    const fetchQuoteInfo = async () => {
      setQuotePending(true)
      try {
        const inTokenAddress = fromAsset.address === 'ETH' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : fromAsset.address
        const outTokenAddress = toAsset.address === 'ETH' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : toAsset.address
        const requestUrl = `https://open-api.openocean.finance/v3/linea/quote?inTokenAddress=${inTokenAddress}&outTokenAddress=${outTokenAddress}&amount=${Number(
          fromAmount,
        )}&gasPrice=${formatGwei(
          gasPrices.fast,
        )}&slipagge=${slippage}&referrer=0xDb73ba19F072D0Fbc865781Ba468A9F8B77aD2C4&enabledDexIds=${EnabledDexIds}&connectors=${Connectors}`
        const response = await fetch(requestUrl, {
          method: 'GET',
        })
        const res = await response.json()
        setBestTrade({
          ...res.data,
          priceImpact: parseInt(res.data?.price_impact?.substring(1)),
          outputAmount: fromWei(res.data?.outAmount, toAsset?.decimals)?.toNumber(),
          name: 'OpenOcean',
        })
      } catch (error) {
        console.log('best swap error :>> ', error)
        setBestTrade(null)
      }
      setQuotePending(false)
    }
    if (fromAsset && toAsset && !isInvalidAmount(fromAmount) && fromAsset.address.toLowerCase() !== toAsset.address.toLowerCase()) {
      fetchQuoteInfo()
    } else {
      setBestTrade(null)
    }
  }, [fromAsset, toAsset, fromAmount, fastRefresh])

  return { bestTrade, quotePending }
}

const useKyberQuoteSwap = (fromAsset, toAsset, fromAmount) => {
  const [bestTrade, setBestTrade] = useState()
  const [quotePending, setQuotePending] = useState(false)
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchQuoteInfo = async () => {
      setQuotePending(true)
      try {
        const inTokenAddress = fromAsset.address === 'ETH' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : fromAsset.address
        const outTokenAddress = toAsset.address === 'ETH' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : toAsset.address
        const requestUrl = `${KYBER_API}/routes?tokenIn=${inTokenAddress}&tokenOut=${outTokenAddress}&amountIn=${toWei(fromAmount, fromAsset.decimals)
          .dp(0)
          .toString()}&source=lynex`
        // &feeAmount=5&chargeFeeBy=currency_out&isInBps=true&feeReceiver=0x44376f8F52407ed66E6D3356535410A7DCd4652B`
        const response = await fetch(requestUrl, {
          method: 'GET',
        })
        const res = await response.json()
        setBestTrade({
          ...res.data,
          outputAmount: fromWei(res.data?.routeSummary?.amountOut, toAsset?.decimals)?.toNumber(),
          outAmount: res.data?.routeSummary?.amountOut,
          name: 'KyberSwap',
        })
      } catch (error) {
        console.log('best swap error :>> ', error)
        setBestTrade(null)
      }
      setQuotePending(false)
    }
    if (fromAsset && toAsset && !isInvalidAmount(fromAmount) && fromAsset.address.toLowerCase() !== toAsset.address.toLowerCase()) {
      fetchQuoteInfo()
    } else {
      setBestTrade(null)
    }
  }, [fromAsset, toAsset, fromAmount, fastRefresh])

  return { bestTrade, quotePending }
}

const useOdosQuoteSwap = (fromAsset, toAsset, fromAmount, slippage) => {
  const [bestTrade, setBestTrade] = useState()
  const [quotePending, setQuotePending] = useState(false)
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3Wagmi()

  useEffect(() => {
    const fetchQuoteInfo = async () => {
      setQuotePending(true)
      try {
        const inTokenAddress = fromAsset.address === 'ETH' ? '0x0000000000000000000000000000000000000000' : fromAsset.address
        const outTokenAddress = toAsset.address === 'ETH' ? '0x0000000000000000000000000000000000000000' : toAsset.address
        const quoteRequestBody = {
          chainId: defaultChainId, // desired chainId
          inputTokens: [
            {
              tokenAddress: Web3.utils.toChecksumAddress(inTokenAddress),
              amount: toWei(fromAmount, fromAsset.decimals).dp(0).toString(), // input amount as a string in fixed integer precision
            },
          ],
          outputTokens: [
            {
              tokenAddress: Web3.utils.toChecksumAddress(outTokenAddress), // checksummed output token address
              proportion: 1,
            },
          ],
          userAddr: Web3.utils.toChecksumAddress(account), // checksummed user address
          slippageLimitPercent: slippage, // set your slippage limit percentage (1 = 1%),
          disableRFQs: true,
          pathId: true,
          simulate: true,
          sourceWhitelist: ODOS_SOURCES,
          referralCode: 1930311944,
        }
        const response = await fetch(ODOS_API, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(quoteRequestBody),
        })
        if (response.status === 200) {
          const res = await response.json()
          setBestTrade({
            ...res,
            priceImpact: parseInt(res?.percentDiff),
            outputAmount: fromWei(res?.outAmounts[0], toAsset?.decimals)?.toNumber(),
            outAmount: res?.outAmounts[0]?.toString(),
            name: 'Odos',
          })
        } else {
          console.error('Error in Quote:', response)
          setBestTrade(null)
        }
      } catch (error) {
        console.log('best swap error :>> ', error)
        setBestTrade(null)
      }
      setQuotePending(false)
    }
    if (fromAsset && toAsset && !isInvalidAmount(fromAmount) && fromAsset.address.toLowerCase() !== toAsset.address.toLowerCase()) {
      fetchQuoteInfo()
    } else {
      setBestTrade(null)
    }
  }, [fromAsset, toAsset, fromAmount, fastRefresh])

  return { bestTrade, quotePending }
}

const useKyberSwapSwap = () => {
  const [swapPending, setSwapPending] = useState(false)
  const { account, chainId: networkId } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleBestSwap = useCallback(
    async (fromAsset, toAsset, fromAmount, toAmount, kyberTrade, slippage) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const swapuuid = uuidv4()
      const routerAddress = kyberTrade.routerAddress
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Swap ${fromAsset.symbol} for ${toAsset.symbol}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${fromAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [swapuuid]: {
              desc: `Swap ${formatAmount(fromAmount)} ${fromAsset.symbol} for ${formatAmount(toAmount)} ${toAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (fromAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, fromAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, fromAsset.decimals).lt(fromAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setSwapPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const value = fromAsset.address === 'ETH' ? kyberTrade.routeSummary.amountIn : '0'
      const payload = {
        routeSummary: kyberTrade.routeSummary,
        sender: account,
        recipient: account,
        slippageTolerance: slippage * 100,
      }
      const response = await fetch(`${KYBER_API}/route/build`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      const res = await response.json()
      const { data } = res
      try {
        await sendV3Contract(dispatch, key, swapuuid, web3, account, routerAddress, data.data, value, networkId)
      } catch (err) {
        console.log('best swap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Swap Successful',
        }),
      )
      trackSwap({
        eventName: 'swap-kyber',
        walletAddress: account,
        fromAmount: Number(fromAmount),
        fromCurrency: fromAsset.symbol,
        toAmount: new BigNumber(toAmount).toNumber(),
        toCurrency: toAsset.symbol,
        contractAddress: routerAddress,
        amountUSD: new BigNumber(toAmount).toNumber() * toAsset.price,
      })
      setSwapPending(false)
    },
    [account, web3, networkId],
  )

  return { onBestSwap: handleBestSwap, swapPending }
}

const useOpenOceanSwap = () => {
  const [swapPending, setSwapPending] = useState(false)
  const { account, chainId: networkId } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const gasPrices = useGasPrice()

  const handleBestSwap = useCallback(
    async (fromAsset, toAsset, fromAmount, toAmount, slippage) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const swapuuid = uuidv4()
      const routerAddress = getOpenOceanRouterAddress()
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Swap ${fromAsset.symbol} for ${toAsset.symbol}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${fromAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [swapuuid]: {
              desc: `Swap ${formatAmount(fromAmount)} ${fromAsset.symbol} for ${formatAmount(toAmount)} ${toAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (fromAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, fromAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, fromAsset.decimals).lt(fromAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setSwapPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const inTokenAddress = fromAsset.address === 'ETH' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : fromAsset.address
      const outTokenAddress = toAsset.address === 'ETH' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : toAsset.address
      const response = await fetch(
        `https://open-api.openocean.finance/v3/linea/swap_quote?inTokenAddress=${inTokenAddress}&outTokenAddress=${outTokenAddress}&amount=${new BigNumber(
          fromAmount,
        )
          .dp(fromAsset.decimals)
          .toString(10)}&gasPrice=${formatGwei(gasPrices.fast)}&slippage=${slippage}&account=${account}&enabledDexIds=${EnabledDexIds}
          &referrer=0xDb73ba19F072D0Fbc865781Ba468A9F8B77aD2C4&connectors=${Connectors}`,
        {
          method: 'GET',
        },
      )
      const res = await response.json()
      const { data, value } = res.data
      try {
        await sendV3Contract(dispatch, key, swapuuid, web3, account, routerAddress, data, value, networkId)
      } catch (err) {
        console.log('best swap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Swap Successful',
        }),
      )
      trackSwap({
        eventName: 'swap-open-ocean',
        walletAddress: account,
        fromAmount: Number(fromAmount),
        fromCurrency: fromAsset.symbol,
        toAmount: new BigNumber(toAmount).toNumber(),
        toCurrency: toAsset.symbol,
        contractAddress: routerAddress,
        amountUSD: new BigNumber(fromAmount).toNumber() * fromAsset.price,
      })
      setSwapPending(false)
    },
    [account, web3, networkId],
  )

  return { onBestSwap: handleBestSwap, swapPending }
}

const useOdosSwap = () => {
  const [swapPending, setSwapPending] = useState(false)
  const { account, chainId: networkId } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleBestSwap = useCallback(
    async (fromAsset, toAsset, fromAmount, toAmount, slippage, pathId) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const swapuuid = uuidv4()
      // TODO: Dynamic
      const routerAddress = '0x2d8879046f1559E53eb052E949e9544bCB72f414'
      setSwapPending(true)
      dispatch(
        openTransaction({
          key,
          title: `Swap ${fromAsset.symbol} for ${toAsset.symbol}`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${fromAsset.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [swapuuid]: {
              desc: `Swap ${formatAmount(fromAmount)} ${fromAsset.symbol} for ${formatAmount(toAmount)} ${toAsset.symbol}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      if (fromAsset.address !== 'ETH') {
        const tokenContract = getERC20Contract(web3, fromAsset.address)
        const allowance = await getAllowance(tokenContract, routerAddress, account)
        if (fromWei(allowance, fromAsset.decimals).lt(fromAmount)) {
          isApproved = false
          try {
            await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [routerAddress, MAX_UINT256], account)
          } catch (err) {
            console.log('approve 0 error :>> ', err)
            setSwapPending(false)
            return
          }
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      const assembleRequestBody = {
        userAddr: Web3.utils.toChecksumAddress(account), // the checksummed address used to generate the quote
        pathId: pathId, // Replace with the pathId from quote response
        simulate: true, // this can be set to true if the user isn't doing their own estimate gas call for the transaction
      }

      try {
        const response = await fetch(ODOS_ASSEMBLE, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(assembleRequestBody),
        })

        if (response.status === 200) {
          const assembledTransaction = await response.json()
          // 2. Extract transaction object from assemble API response
          const transaction = assembledTransaction.transaction
          await sendV3Contract(dispatch, key, swapuuid, web3, account, routerAddress, transaction.data, transaction.value, networkId)
        } else {
          console.error('Error in Transaction Assembly:', response)
          setSwapPending(false)
          return
        }
      } catch (err) {
        console.log('best swap error :>> ', err)
        setSwapPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Swap Successful',
        }),
      )
      trackSwap({
        eventName: 'swap-odos',
        walletAddress: account,
        fromAmount: Number(fromAmount),
        fromCurrency: fromAsset.symbol,
        toAmount: new BigNumber(toAmount).toNumber(),
        toCurrency: toAsset.symbol,
        contractAddress: routerAddress,
        amountUSD: new BigNumber(fromAmount).toNumber() * fromAsset.price,
      })
      setSwapPending(false)
    },
    [account, web3, networkId],
  )

  return { onBestSwap: handleBestSwap, swapPending }
}

const TOLERANCE = 0.03
const useBestQuoteSwap = (fromAsset, toAsset, fromAmount, slippage, deadline) => {
  const [quotePending, setQuotePending] = useState(false)
  const [trades, setTrades] = useState([])

  // const { bestTrade: openOceanTrade, quotePending: openOceanPending } = useOpenOceanQuoteSwap(fromAsset, toAsset, fromAmount, slippage)
  const openOceanTrade = null
  const openOceanPending = false

  const { bestTrade: v1Best, quotePending: v1Pending, priceImpact: v1PriceImpact } = useQuoteSwap(wrappedAsset(fromAsset), wrappedAsset(toAsset), fromAmount)

  const { bestTrade: kyberTrade, quotePending: kyberPending } = useKyberQuoteSwap(fromAsset, toAsset, fromAmount)

  const { bestTrade: odosTrade, quotePending: odosPending } = useOdosQuoteSwap(fromAsset, toAsset, fromAmount, slippage)

  const inCurrency = useCurrency(fromAsset ? fromAsset.address : undefined)
  const outCurrency = useCurrency(toAsset ? toAsset.address : undefined)
  const parsedAmount = tryParseAmount(fromAmount, inCurrency)
  const bestV3TradeExactIn = useBestV3TradeExactIn(parsedAmount, outCurrency)

  let bestTradeAmount = openOceanTrade?.outputAmount
  let kyberTradeAmount = kyberTrade?.outputAmount
  let odosTradeAmount = odosTrade?.outputAmount
  let v1Amount = v1Best?.outputAmount
  let v3Amount = bestV3TradeExactIn?.outputAmount
  v3Amount = Number.isNaN(v3Amount) || !v3Amount ? 0 : v3Amount
  v1Amount = Number.isNaN(v1Amount) || !v1Amount ? 0 : v1Amount
  bestTradeAmount = Number.isNaN(bestTradeAmount) || !bestTradeAmount ? 0 : bestTradeAmount
  kyberTradeAmount = Number.isNaN(kyberTradeAmount) || !kyberTradeAmount ? 0 : kyberTradeAmount
  kyberTradeAmount = Number.isNaN(kyberTradeAmount) || !kyberTradeAmount ? 0 : kyberTradeAmount
  odosTradeAmount = Number.isNaN(odosTradeAmount) || !odosTradeAmount ? 0 : odosTradeAmount

  const allowedSlippage = new Percent(JSBI.BigInt(parseInt(slippage) * 100), JSBI.BigInt(10000))

  const { error, callback: swapCallback, pending: swapV3Pending } = useSwapCallback(bestV3TradeExactIn?.trade, allowedSlippage, deadline)
  const { onBestSwap: openOceanSwap, swapPending: swapOpenOceanPending } = useOpenOceanSwap()
  const { onSwap: v1Swap, swapPending: swapV1Pending } = useProceedSwap()
  const { onBestSwap: kyberSwap, swapPending: swapKyberPending } = useKyberSwapSwap()
  const { onBestSwap: odosSwap, swapPending: odosSwapPending } = useOdosSwap()

  let bestTrade = openOceanTrade

  let bestSwap = openOceanSwap
  let openOceanParams = [fromAsset, toAsset, fromAmount, fromWei(openOceanTrade?.outAmount, toAsset?.decimals)?.toString(10), slippage]
  let params = openOceanParams
  let isOpenOcean = false

  const v1Params = [fromAsset, toAsset, fromAmount, v1Best, slippage, deadline]
  const v3Params = []
  const kyberParams = [fromAsset, toAsset, fromAmount, fromWei(kyberTrade?.routeSummary?.amountOut, toAsset?.decimals)?.toString(10), kyberTrade, slippage]
  const odosParams = [fromAsset, toAsset, fromAmount, fromWei(openOceanTrade?.outAmount, toAsset?.decimals)?.toString(10), slippage, odosTrade?.pathId]
  useEffect(() => {
    const sortedTrades = [
      [openOceanTrade, openOceanSwap, openOceanParams],
      [v1Best, v1Swap, v1Params],
      [bestV3TradeExactIn, swapCallback, v3Params],
      // [kyberTrade, kyberSwap, kyberParams],
      [odosTrade, odosSwap, odosParams],
    ].sort((a, b) => (b[0]?.outputAmount || 0) - (a[0]?.outputAmount || 0))
    setTrades(sortedTrades)
  }, [openOceanTrade?.outputAmount, v1Best?.outputAmount, bestV3TradeExactIn?.outputAmount, kyberTrade?.outputAmount, odosTrade?.outputAmount, slippage])

  useEffect(() => {
    setQuotePending(
      openOceanPending || v1Pending || odosPending || (!bestV3TradeExactIn?.trade && bestV3TradeExactIn?.state !== 'NO_ROUTE_FOUND') || kyberPending,
    )
  }, [openOceanPending, v1Pending, odosPending, !bestV3TradeExactIn?.trade, kyberPending])

  const swapPending = swapOpenOceanPending || swapV1Pending || swapV3Pending || swapKyberPending || odosSwapPending

  // TODO: reconsider this logic to exlcude openocean
  // bestSwap = trades[0] && trades[0][0]?.name !== 'OpenOcean' ? trades[0][1] : odosSwap
  // params = trades[0] && trades[0][0]?.name !== 'OpenOcean' ? trades[0][2] : odosParams
  // bestTrade = trades[0] && trades[0][0]?.name !== 'OpenOcean' && !quotePending ? { ...trades[0][0] } : quotePending ? null : odosTrade ? { ...odosTrade } : null
  bestSwap = trades[0] ? trades[0][1] : null
  params = trades[0] ? trades[0][2] : null
  bestTrade = trades[0] && !quotePending ? { ...trades[0][0] } : null
  return {
    trades,
    isOpenOcean,
    bestTrade,
    quotePending,
    bestSwap,
    params,
    swapPending,
    swapCallback,
    openOceanSwap,
    v1Swap,
    kyberSwap,
    error,
  }
}

// eslint-disable-next-line no-unused-vars
function relDiff(a, b) {
  return 100 * Math.abs((a - b) / ((a + b) / 2))
}

export { useQuoteSwap, useProceedSwap, useOpenOceanQuoteSwap, useOpenOceanSwap, useBestQuoteSwap }
